.dashboard-container2 {
    margin: 0 auto;
    background: url(../../../public/assets/background2.jpeg) no-repeat;
    background-size: contain;
    background-color: #000;
    padding-top: 2px;
    width: 1394px;
    height: 784px;

}



.dash2-title {
    color: #2FC8FF;
    margin: 0px auto;
    text-align: center;
    font-size: 24px;
    font-family:'zkgdh_font';
}


.dash2-datetime {
    position: absolute;
    top: 28px;
    left: 47px;
    color: #2DCCFF;
    font-size: 12px;
    font-family: 'din_medium_font';
}

.dash2-real-price {
    position: absolute;
    top: 50px;
    left: 47px;
    color: #00FFF1;
    font-size: 14px;
}


.dash2-pie-chart {
    position: absolute;
    top: 147px;
    left: 80px;
}


.dash2-pie-chart2 {
    position: absolute;
    top: 470px;
    left: 80px;
}


.dash2-total-count {
    color: #00FFDF;
    font-family: 'din_black_italic_font';
    font-size: 36px;
}

.dash2-oilfield-count {
    position: absolute;
    top: 78px;
    left: 438px;
}

.dash2-count-unit {
    font-size: 15px;
}


.dash2-oilwell-count {
    position: absolute;
    top: 78px;
    left: 605px;
}


.dash2-iot-count {
    position: absolute;
    top: 78px;
    left: 772px;
}

.dash2-sensor-count {
    font-size: 13px;
}


.dash2-sensor-temp-count {
    position: absolute;
    top: 57px;
    left: 984px;
}

.dash2-sensor-lock-count {
    position: absolute;
    top: 74px;
    left: 984px;
}

.dash2-sensor-water-count {
    position: absolute;
    top: 91px;
    left: 984px;
}

.dash2-sensor-height-count {
    position: absolute;
    top: 109px;
    left: 984px;
}

.dash2-table {
    position: absolute;
    top: 164px;
    left: 328px;
    color: #A0EBFF;
    width: 737px;
    height: 180px;
    font-size: 12px;
}

.dash2-table-row {
    display: flex;
    text-align: center;
    height: 23px;
    line-height: 23px;
    cursor: pointer;
}

.dash2-table-column {
    width: 184px;
    border: 1px solid #4CA3D1;
    margin-right:-1px;
    margin-bottom: -1px;
}


.dash2-table-header {
    background: #0D3A59;
    color: #22B9FF;
    font-weight: 600;
}


.dash2-selected-row {
    border: 2px solid #00FDDC;
    box-sizing: content-box;
    margin-right: -2px;
    margin-left: -2px;
    color: #00FDDC;
}


.dash2-selected-row::before{
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    margin-left: -15px;
    margin-top: 5px;
    border-width: 5px 5px 5px 10px;
    border-color: transparent transparent transparent #00FDDC;
}



.dash2-table-row-broken {
    color:#DC2C4B;
}

.dash2-table-row-connected {
    color:#00F4D7;
}

.dash2-collect-bill {
    color: rgb(160, 235, 255);
    /* margin-left: 30px; */
    position: absolute;
    text-align: center;
    width: 100%;
    top: 376px;
}

.dash2-selected-item-iot {
    position: absolute;
    top: 394px;
    width: 100%;
    text-align: center;
    color: #1FC6FF;
    font-size: 12px;
}


.dash2-selected-item-height-bg-working {
    position: absolute;
    top: 451px;
    left: 384px;
    background: url(../../../public/assets/IoT设备状态/液位上报-正常.png) no-repeat;
    background-size: contain;
    width: 33px;
    height: 33px;
}

.dash2-selected-item-height-bg-notworking {
    position: absolute;
    top: 451px;
    left: 384px;
    background: url(../../../public/assets/IoT设备状态/液位上报-未工作.png) no-repeat;
    background-size: contain;
    width: 33px;
    height: 33px;
}

.dash2-selected-item-height-bg-broken {
    position: absolute;
    top: 451px;
    left: 384px;
    background: url(../../../public/assets/IoT设备状态/液位上报-故障.png) no-repeat;
    background-size: contain;
    width: 33px;
    height: 33px;
}



.dash2-selected-item-height-text {
    position: absolute;
    font-size: 13px;
    color: #fff;
    top: 490px;
    left: 376px;
}


.dash2-selected-item-height-status-working {
    position: absolute;
    font-size: 12px;
    color: #00FFDF;
    top: 525px;
    left: 382px;
    display: flex;
    align-items: center;
    transform: scale(0.83);
}


.dash2-selected-item-height-status-notworking {
    position: absolute;
    font-size: 12px;
    color: #fff;
    top: 525px;
    left: 382px;
    display: flex;
    align-items: center;
    transform: scale(0.83);
}

.dash2-selected-item-height-status-broken {
    position: absolute;
    font-size: 12px;
    color: #DC2C4B;
    top: 525px;
    left: 382px;
    display: flex;
    align-items: center;
    transform: scale(0.83);
}




.dash2-selected-item-water-bg-working {
    position: absolute;
    top: 451px;
    left: 532px;
    background: url(../../../public/assets/IoT设备状态/智能水表-正常.png) no-repeat;
    background-size: contain;
    width: 33px;
    height: 33px;
}

.dash2-selected-item-water-bg-notworking {
    position: absolute;
    top: 451px;
    left: 532px;
    background: url(../../../public/assets/IoT设备状态/智能水表-未工作.png) no-repeat;
    background-size: contain;
    width: 33px;
    height: 33px;
}

.dash2-selected-item-water-bg-broken {
    position: absolute;
    top: 451px;
    left: 532px;
    background: url(../../../public/assets/IoT设备状态/智能水表-故障.png) no-repeat;
    background-size: contain;
    width: 33px;
    height: 33px;
}


.dash2-selected-item-water-text {
    position: absolute;
    font-size: 13px;
    color: #fff;
    top: 490px;
    left: 515px;
}


.dash2-selected-item-water-status-working {
    position: absolute;
    font-size: 12px;
    color: #00FFDF;
    top: 525px;
    left: 530px;
    display: flex;
    align-items: center;
    transform: scale(0.83);
}


.dash2-selected-item-water-status-notworking {
    position: absolute;
    font-size: 12px;
    color: #fff;
    top: 525px;
    left: 522px;
    display: flex;
    align-items: center;
    transform: scale(0.83);
    width: 60px;
    text-align: center;
}


.dash2-selected-item-water-status-broken {
    position: absolute;
    font-size: 12px;
    color: #DC2C4B;
    top: 525px;
    left: 530px;
    display: flex;
    align-items: center;
    transform: scale(0.83);
}



.dash2-selected-item-temp-bg-working {
    position: absolute;
    top: 451px;
    left: 682px;
    background: url(../../../public/assets/IoT设备状态/温度上报-正常.png) no-repeat;
    background-size: contain;
    width: 33px;
    height: 33px;
}

.dash2-selected-item-temp-bg-notworking {
    position: absolute;
    top: 451px;
    left: 682px;
    background: url(../../../public/assets/IoT设备状态/温度上报-未工作.png) no-repeat;
    background-size: contain;
    width: 33px;
    height: 33px;
}

.dash2-selected-item-temp-bg-broken {
    position: absolute;
    top: 451px;
    left: 682px;
    background: url(../../../public/assets/IoT设备状态/温度上报-故障.png) no-repeat;
    background-size: contain;
    width: 33px;
    height: 33px;
}


.dash2-selected-item-temp-text {
    position: absolute;
    font-size: 13px;
    color: #fff;
    top: 490px;
    left: 674px;
}


.dash2-selected-item-temp-status-working {
    position: absolute;
    font-size: 12px;
    color: #00FFDF;
    top: 525px;
    left: 671px;
    display: flex;
    align-items: center;
    transform: scale(0.83);
}

.dash2-selected-item-temp-status-notworking {
    position: absolute;
    font-size: 12px;
    color: #fff;
    top: 525px;
    left: 671px;
    display: flex;
    align-items: center;
    transform: scale(0.83);
}


.dash2-selected-item-temp-status-broken {
    position: absolute;
    font-size: 12px;
    color: #DC2C4B;
    top: 525px;
    left: 675px;
    display: flex;
    align-items: center;
    transform: scale(0.83);

    width: 60px;
    text-align: center;
}



.dash2-selected-item-heat-bg-working {
    position: absolute;
    top: 451px;
    left: 828px;
    background: url(../../../public/assets/IoT设备状态/电热丝-正常.png) no-repeat;
    background-size: contain;
    width: 33px;
    height: 33px;
}

.dash2-selected-item-heat-bg-notworking {
    position: absolute;
    top: 451px;
    left: 828px;
    background: url(../../../public/assets/IoT设备状态/电热丝-未工作.png) no-repeat;
    background-size: contain;
    width: 33px;
    height: 33px;
}

.dash2-selected-item-heat-bg-broken {
    position: absolute;
    top: 451px;
    left: 828px;
    background: url(../../../public/assets/IoT设备状态/电热丝-故障.png) no-repeat;
    background-size: contain;
    width: 33px;
    height: 33px;
}


.dash2-selected-item-heat-text {
    position: absolute;
    font-size: 13px;
    color: #fff;
    top: 490px;
    left: 826px;
}


.dash2-selected-item-heat-status-working {
    position: absolute;
    font-size: 12px;
    color: #00FFDF;
    top: 525px;
    left: 826px;
    display: flex;
    align-items: center;
    transform: scale(0.83);
}

.dash2-selected-item-heat-status-notworking {
    position: absolute;
    font-size: 12px;
    color: #fff;
    top: 525px;
    left: 826px;
    display: flex;
    align-items: center;
    transform: scale(0.83);
}

.dash2-selected-item-heat-status-broken {
    position: absolute;
    font-size: 12px;
    color: #DC2C4B;
    top: 525px;
    left: 826px;
    display: flex;
    align-items: center;
    transform: scale(0.83);
}


.dash2-selected-item-lock-bg-working {
    position: absolute;
    top: 451px;
    left: 978px;
    background: url(../../../public/assets/IoT设备状态/智能锁-正常.png) no-repeat;
    background-size: contain;
    width: 33px;
    height: 33px;
}


.dash2-selected-item-lock-bg-notworking {
    position: absolute;
    top: 451px;
    left: 978px;
    background: url(../../../public/assets/IoT设备状态/智能锁-未工作.png) no-repeat;
    background-size: contain;
    width: 33px;
    height: 33px;
}

.dash2-selected-item-lock-bg-broken {
    position: absolute;
    top: 451px;
    left: 978px;
    background: url(../../../public/assets/IoT设备状态/智能锁-故障.png) no-repeat;
    background-size: contain;
    width: 33px;
    height: 33px;
}


.dash2-selected-item-lock-text {
    position: absolute;
    font-size: 13px;
    color: #fff;
    top: 490px;
    left: 976px;
}


.dash2-selected-item-lock-status-working {
    position: absolute;
    font-size: 12px;
    color: #00FFDF;
    top: 525px;
    left: 974px;
    display: flex;
    align-items: center;
    transform: scale(0.83);
}

.dash2-selected-item-lock-status-notworking {
    position: absolute;
    font-size: 12px;
    color: #fff;
    top: 525px;
    left: 974px;
    display: flex;
    align-items: center;
    transform: scale(0.83);
}


.dash2-selected-item-lock-status-broken {
    position: absolute;
    font-size: 12px;
    color: #DC2C4B;
    top: 525px;
    left: 974px;
    display: flex;
    align-items: center;
    transform: scale(0.83);
}



.dash2-selected-item-status-icon-working {
    width: 12px;
    height: 12px;
    background: url(../../../public/assets/state-icon/正常.png) no-repeat;
    background-size: contain;
    margin-right:2px;
}


.dash2-selected-item-status-icon-notworking{
    width: 12px;
    height: 12px;
    background: url(../../../public/assets/state-icon/未工作.png) no-repeat;
    background-size: contain;
    margin-right:2px;
}


.dash2-selected-item-status-icon-broken{
    width: 12px;
    height: 12px;
    background: url(../../../public/assets/state-icon/故障.png) no-repeat;
    background-size: contain;
    margin-right:2px;
}


.dash2-selected-item-output {
    position: absolute;
    top: 580px;
    width: 100%;
    text-align: center;
    color: #1FC6FF;
    font-size: 15px;
}


.dash2LineChart {
    position: absolute;
    top: 570px;
    left: 295px;
}


.dash2PieChart3 {
    position: absolute;
    top:10px;
    left: 1099px;
}


.dash2PieChart3Bg {
    position: absolute;
    top:88px;
    left: 1280px;
    width: 33px;
    height: 33px;
    background: url(../../../public/assets/state-icon/油井投产状态.png) no-repeat;
    background-size: contain;
}

.dash2PieChart3BgCircle {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    margin-left: -17px;
    margin-top: -18px;
    border: 1px solid gray;
}


.dash2-runtime-name {
    position: absolute;
    top: 200px;
    left:1118px;
    color: #00F4D7;
    font-size: 22px;
}

.dash2-runtime-text {
    position: absolute;
    top: 220px;
    left:1180px;
    color: #A0EBFF;
    font-size: 12px;
}


.dash2-runtime-text .runtime {
    font-size: 22px;
    font-family: 'din_medium_font';
}


.dash2-total-oil {
    position: absolute;
    top: 272px;
    left: 1162px;
    width: 120px;
    height: 120px;
    background: url(../../../public/assets/oil-bg.png) no-repeat;
    background-size: contain;
    color: #02F6A4;
    text-align: center;
}


.dash2-total-oil-number {
    font-size: 22px;
    font-family: 'din_medium_font';
    text-align: center;
    margin-top: 26px;
}

.dash2-total-oil-unit {
    font-size: 14px;
}



.dash2-total-water {
    position: absolute;
    top: 408px;
    left: 1162px;
    width: 120px;
    height: 120px;
    background: url(../../../public/assets/water-bg.png) no-repeat;
    background-size: contain;
    color: #1DCCFF;
    text-align: center;
}


.dash2-total-water-number {
    font-size: 22px;
    font-family: 'din_medium_font';
    text-align: center;
    margin-top: 26px;
}

.dash2-total-water-unit {
    font-size: 14px;
}

.dash2-total-dealt-oil {
    position: absolute;
    top: 588px;
    left: 1220px;
    color: #02F6A4;
    font-size: 36px;
    font-family: 'din_black_italic_font';
}



.dash2-total-dealt-water {
    position: absolute;
    top: 676px;
    left: 1220px;
    color: #1DCCFF;
    font-size: 36px;
    font-family: 'din_black_italic_font';
}

.dash2-total-dealt-unit {
    font-size: 22px;
}
.wordColor{
    color:red;
    font-size: 10px;
}